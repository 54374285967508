import type { AppDispatch } from "src/store/store";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  CompanyIdentificationModal,
  Fieldset,
  Input,
  InputMask,
  Reset,
  Separator,
  Submit,
} from "src/components";
import { taxNumberMask } from "src/validation/masks";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { setModalData, setModalOpen } from "src/store/slices/modalSlice";
import { useValidationSchema } from "src/hooks/useValidationSchema";
import { companyIdentificationValidationSchema } from "src/validation/schemes";
import {
  DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsResponse,
  useDeleteRegrequestMutation,
  useGetRegrequestsQuery,
  useIdentifyTenantMutation,
} from "src/store/serviceApi";
import { useToast } from "src/components/_ui/toast/use-toast";

const initialValues = {
  subscriptions: [
    {
      productKey: "",
      taxNumber: "",
    },
  ],
};

const MODAL_ID = "company-identification-is-exists-modal";

interface Props {}

const CompanyIdentificationForm: React.FC<Props> = () => {
  const navigate = useNavigate();
  const validate = useValidationSchema(companyIdentificationValidationSchema);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [identifyTenant] = useIdentifyTenantMutation();
  const [deleteRegrequest] = useDeleteRegrequestMutation();
  const { data: regrequestsData } = useGetRegrequestsQuery();
  const { toast } = useToast();

  const regrequest =
    regrequestsData && regrequestsData.find((data) => data.state === "Sent");

  const handleNavigate = (
    tenants: DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsResponse[]
  ) => {
    navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION_RESULT, {
      state: {
        result: tenants,
        flowCode: regrequest?.code,
        navigatedFromCompanies: location.state?.navigatedFromCompanies,
      },
    });
  };

  const handleIdentification = async (
    { subscriptions }: typeof initialValues,
    form: any
  ) => {
    if (subscriptions) {
      let identifiedTenants: DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsResponse[] =
        [];

      for (const subscription of subscriptions) {
        const payload = {
          body: subscription,
        };

        await identifyTenant(payload)
          .unwrap()
          .then((response) => {
            identifiedTenants.push(response);
          })
          .catch((error) => {
            console.log("COMPANY IDENTIFICATION FORM ERROR", error);
          });
      }

      dispatch(
        setModalData({
          tenants: identifiedTenants,
        })
      );

      if (identifiedTenants.some((item) => item.isExists)) {
        dispatch(setModalOpen(MODAL_ID));
        form.restart(initialValues);
        return;
      }

      if (identifiedTenants.length > 0) {
        handleNavigate(identifiedTenants);
      }

      form.restart(initialValues);
    }
  };

  const handleReset = async () => {
    if (regrequest?.id) {
      const payload = {
        id: regrequest.id,
      };

      await deleteRegrequest(payload)
        .unwrap()
        .then((response) => {
          console.log("deleteRegrequest response", response);
          navigate(
            INTERNAL_ROUTE.COMPANY_IDENTIFICATION_BY_THIRD_PARTY_CANCELLED
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const toInputUppercase = (e: any) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  return (
    <>
      <Form
        onSubmit={handleIdentification}
        initialValues={initialValues}
        validate={validate}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <div className="flex flex-col gap-y-4 m-auto">
              <FieldArray name="subscriptions">
                {({ fields }) => {
                  return (
                    <div className="flex flex-col gap-y-4">
                      <div className="flex flex-col gap-y-4 md:max-h-[25vh] overflow-auto scrollbar-none">
                        {fields.map((name, index) => (
                          <div key={name} className="flex flex-col gap-y-3">
                            {fields.value.length >= 2 && index >= 1 && (
                              <Separator>{`Cég ${index + 1}`}</Separator>
                            )}

                            <Fieldset
                              label="Kulcs-Ügyvitel termékszámának megadása"
                              hint="A termékszám egy olyan titkos, egyedi azonosító, amit Kulcs-Ügyvitel termék megvásárlásakor kapott a rendszerért felelős kolléga a cégednél. Ez a program vásárlásakor kapott licenszkulcs."
                            >
                              <Input
                                onInput={toInputUppercase}
                                name={`${name}.productKey`}
                                placeholder="123456789123456789"
                              />
                            </Fieldset>
                            <Fieldset label="Kapcsolódó Cég Adószáma">
                              <InputMask
                                name={`${name}.taxNumber`}
                                options={taxNumberMask}
                                placeholder="xxxxxxxx-x-xx"
                              />
                            </Fieldset>

                            {/**
                             * ==============================================================
                             * MEMO: DO NOT REMOVE FOR FUTURE PURPOSES!! WE NEED THAT LATER!!
                             * ==============================================================
                             *
                            {fields.value.length > 1 && (
                              <button
                                type="button"
                                onClick={() => fields.remove(index)}
                                className="self-end text-xs text-destructive"
                              >
                                Termékszám törlése
                              </button>
                            )} */}
                          </div>
                        ))}
                      </div>

                      {/**
                       * ==============================================================
                       * MEMO: DO NOT REMOVE FOR FUTURE PURPOSES!! WE NEED THAT LATER!!
                       * ==============================================================
                       **/}
                      {/* <Button
                      variant="Inner"
                      onClick={(e) => {
                        e.preventDefault();
                        fields.push({ productKey: "", taxNumber: "" });
                      }}
                    >
                      <Icon
                        icon="plus"
                        variant="SmallContainer"
                        size={14}
                        className="text-primary"
                      />{" "}
                      <P className="text-primary ">
                        További termékszámok hozzáadása
                      </P>
                    </Button> */}
                    </div>
                  );
                }}
              </FieldArray>
              <div className="flex items-center justify-end gap-4">
                {regrequest?.id && <Reset>Nem rendelkezem termékszámmal</Reset>}
                <Submit className="md:w-auto w-full">
                  Termékszám ellenőrzése
                </Submit>
              </div>
            </div>
          </form>
        )}
      </Form>

      <CompanyIdentificationModal />
    </>
  );
};

export default CompanyIdentificationForm;
