import { useLocation, useNavigate } from "react-router-dom";

import {
  Callout,
  CompanyIdentificationCard,
  CompanyIdentificationLayout,
  CompanyIdentificationConfirmed,
  CompanyIdentificationUnconfirmed,
  CompanyIdentificationPartiallyConfirmed,
} from "src/components";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { useEffect } from "react";
import {
  DataPointApplicationFeaturesTenantsCommandsCreateTenantCommand,
  DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsResponse,
} from "src/store/serviceApi";

const CompanyIdentificationResult = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION, {
        state: {
          navigatedFromCompanies: location.state?.navigatedFromCompanies,
        },
      });
    }
  }, [location.state]);

  const result:
    | DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsResponse[] =
    location.state?.result;
  const flowCode: DataPointApplicationFeaturesTenantsCommandsCreateTenantCommand["flowCode"] =
    location.state?.flowCode;

  return (
    <CompanyIdentificationLayout>
      <CompanyIdentificationCard>
        {result ? (
          result.length > 0 && result.every(({ isValid }) => isValid) ? (
            <CompanyIdentificationConfirmed
              result={result}
              flowCode={flowCode}
            />
          ) : result.every(({ isValid }) => !isValid) ? (
            <CompanyIdentificationUnconfirmed />
          ) : (
            <CompanyIdentificationPartiallyConfirmed
              result={result}
              flowCode={flowCode}
            />
          )
        ) : (
          <Callout variant="destructive">Hiba történt!</Callout>
        )}
      </CompanyIdentificationCard>
    </CompanyIdentificationLayout>
  );
};

export default CompanyIdentificationResult;
